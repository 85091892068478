(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/jotai-tanstack-query/assets/javascripts/jotai-tanstack-query-development.js') >= 0) return;  svs.modules.push('/components/libs/jotai-tanstack-query/assets/javascripts/jotai-tanstack-query-development.js');
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('@tanstack/query-core'), require('jotai'), require('jotai/utils')) :
      typeof define === 'function' && define.amd ? define(['exports', '@tanstack/query-core', 'jotai', 'jotai/utils'], factory) :
          (global = global || self, factory(global.jotaiTanstackQuery = {}, global.queryCore, global.jotai, global.jotaiUtils));
})(this, (function (exports, queryCore, jotai, utils) {
  var queryClientAtom = jotai.atom(new queryCore.QueryClient());

  var createAtoms = function createAtoms(getOptions, getQueryClient, createObserver, handleAction) {
    var observerCacheAtom = jotai.atom(function () {
      return new WeakMap();
    });
    var refreshAtom = jotai.atom(0);
    var IN_RENDER = Symbol();
    var observerAtom = jotai.atom(function (get) {
      get(refreshAtom);
      var queryClient = getQueryClient(get);
      var options = getOptions(get);
      var observerCache = get(observerCacheAtom);
      var observer = observerCache.get(queryClient);
      if (observer) {
        observer[IN_RENDER] = true;
        observer.setOptions(options, {
          listeners: false
        });
        delete observer[IN_RENDER];
      } else {
        observer = createObserver(queryClient, options);
        observerCache.set(queryClient, observer);
      }
      return observer;
    });
    var baseStatusAtom = jotai.atom(function (get) {
      var observer = get(observerAtom);
      var observable = {
        subscribe: function subscribe(arg) {
          var callback = function callback(result) {
            var notifyResult = function notifyResult() {
              return (typeof arg === 'function' ? arg : arg.next)(result);
            };
            if (observer[IN_RENDER]) {
              Promise.resolve().then(notifyResult);
            } else {
              notifyResult();
            }
          };
          var unsubscribe = observer.subscribe(callback);
          callback(observer.getCurrentResult());
          return {
            unsubscribe: unsubscribe
          };
        }
      };
      var resultAtom = utils.atomWithObservable(function () {
        return observable;
      }, {
        initialValue: observer.getCurrentResult()
      });
      return resultAtom;
    });
    var statusAtom = jotai.atom(function (get) {
      var resultAtom = get(baseStatusAtom);
      return get(resultAtom);
    }, function (get, set, action) {
      var observer = get(observerAtom);
      var refresh = function refresh() {
        var queryClient = getQueryClient(get);
        var observerCache = get(observerCacheAtom);
        observerCache["delete"](queryClient);
        set(refreshAtom, function (c) {
          return c + 1;
        });
      };
      return handleAction(action, observer, refresh);
    });
    var baseDataAtom = jotai.atom(function (get) {
      getOptions(get); 
      var observer = get(observerAtom);
      var observable = {
        subscribe: function subscribe(arg) {
          var callback = function callback(result) {
            if (result.isSuccess && result.data !== undefined || result.isError && !queryCore.isCancelledError(result.error)) {
              var notifyResult = function notifyResult() {
                return (typeof arg === 'function' ? arg : arg.next)(result);
              };
              if (observer[IN_RENDER]) {
                Promise.resolve().then(notifyResult);
              } else {
                notifyResult();
              }
            }
          };
          var unsubscribe = observer.subscribe(callback);
          callback(observer.getCurrentResult());
          return {
            unsubscribe: unsubscribe
          };
        }
      };
      var resultAtom = utils.atomWithObservable(function () {
        return observable;
      });
      return resultAtom;
    });
    var dataAtom = jotai.atom(function (get) {
      var resultAtom = get(baseDataAtom);
      var result = get(resultAtom);
      if (result.error) {
        throw result.error;
      }
      return result.data;
    }, function (_get, set, action) {
      return set(statusAtom, action);
    });
    return [dataAtom, statusAtom];
  };

  function atomsWithQuery(getOptions, getQueryClient) {
    if (getQueryClient === void 0) {
      getQueryClient = function getQueryClient(get) {
        return get(queryClientAtom);
      };
    }
    return createAtoms(getOptions, getQueryClient, function (client, options) {
      return new queryCore.QueryObserver(client, options);
    }, function (action, observer, refresh) {
      if (action.type === 'refetch') {
        if (action.force) {
          observer.remove();
          refresh();
          return;
        }
        return observer.refetch(action.options).then(function () {});
      }
    });
  }

  function atomsWithInfiniteQuery(getOptions, getQueryClient) {
    if (getQueryClient === void 0) {
      getQueryClient = function getQueryClient(get) {
        return get(queryClientAtom);
      };
    }
    return createAtoms(getOptions, getQueryClient, function (client, options) {
      return new queryCore.InfiniteQueryObserver(client, options);
    }, function (action, observer, refresh) {
      if (action.type === 'refetch') {
        if (action.force) {
          observer.remove();
          refresh();
          return;
        }
        return observer.refetch(action.options).then(function () {});
      }
      if (action.type === 'fetchNextPage') {
        return observer.fetchNextPage().then(function () {});
      }
      if (action.type === 'fetchPreviousPage') {
        return observer.fetchPreviousPage().then(function () {});
      }
    });
  }

  function atomsWithMutation(getOptions, getQueryClient) {
    if (getQueryClient === void 0) {
      getQueryClient = function getQueryClient(get) {
        return get(queryClientAtom);
      };
    }
    return createAtoms(getOptions, getQueryClient, function (client, options) {
      return new queryCore.MutationObserver(client, options);
    }, function (action, observer) {
      try {
        return Promise.resolve(observer.mutate.apply(observer, action)).then(function () {});
      } catch (e) {
        return Promise.reject(e);
      }
    });
  }

  exports.atomsWithInfiniteQuery = atomsWithInfiniteQuery;
  exports.atomsWithMutation = atomsWithMutation;
  exports.atomsWithQuery = atomsWithQuery;
  exports.queryClientAtom = queryClientAtom;

}));


 })(window);